@if (isLoading) {
  <app-loading-animation />
}
@if (!isLoading && error === null) {
  <div class="order-headline">
    <div class="order-text">
      <div class="text">
        <h2>Auftrag {{ orderDetail.orderNumber }}</h2>
        <div
          class="aggregate-analysis-dropdown-button"
          (click)="openAggregateAnalysis()"
        >
          <img [src]="getArrowImage()" alt="" />
        </div>
      </div>
      @if (!isShowingAggregateAnalysis) {
        <div class="status">
          <div class="status-symbols">
            <app-chip [status]="orderDetail.status" [hasText]="true" /><span
              >Auftr.status</span
            >
          </div>
          <div class="status-symbols">
            <app-chip
              [status]="orderDetail.hygiene?.status"
              [hasText]="true"
            /><span>Hyg.status</span>
          </div>
        </div>
      }
    </div>

    <div class="action-buttons">
      @if (
        hasUserRole(UserRole.BACKOFFICE_REVIEWER_HYGIENE) ||
        orderDetail.status === OrderStatus.ReadyForShipment ||
        orderDetail.status === OrderStatus.ShippedToCustomer
      ) {
        <div
          (click)="!isDownloading && downloadOrderReport(orderDetail.id)"
          class="report-button cell-styling"
          [ngClass]="{ 'download-disabled': isDownloading }"
        >
          <img src="/assets/download-alt.svg" alt="Download Icon" />
        </div>
      }

      @if (hasUserRole(UserRole.BACKOFFICE_ADMIN)) {
        <app-button
          (click)="generateArtifacts(orderDetail.id)"
          type="primary-icon-button"
          icon="/assets/icons/generate_artifacts.svg"
          label="Generiere Artefakte"
        />
      }
      @if (
        orderDetail.hygiene?.status === HygieneInspectionStatus.InReview &&
        hasUserRole(UserRole.BACKOFFICE_REVIEWER_HYGIENE)
      ) {
        <app-button
          (click)="setInspectionStatus(HygieneInspectionStatus.Completed)"
          type="primary-icon-button"
          icon="/assets/icons/check.svg"
          label="Hygieneinspektion abschließen"
        />
      }

      @if (
        orderDetail.status === OrderStatus.ReadyForShipment &&
        hasUserRole(UserRole.BACKOFFICE_SHIPPER)
      ) {
        <app-button
          (click)="setOrderStatus(OrderStatus.ShippedToCustomer)"
          type="primary-icon-button"
          icon="/assets/icons/versendet.svg"
          label="Versand bestätigen"
        />
      }
      @if (
        orderDetail.status === OrderStatus.Created &&
        (hasUserRole(UserRole.BACKOFFICE_ADMIN) || hasUserRole(UserRole.BACKOFFICE_PLANNER))
      ) {
        <div (click)="toggleDeleteDialog()" class="delete-button cell-styling">
          <img src="/assets/trash.svg" alt="Delete Icon" />
        </div>
      }
    </div>
  </div>
  @if (isShowingAggregateAnalysis) {
    <app-aggregate-analysis [orderDetail]="orderDetail" />
  }

  <app-dialog
    [orderDetail]="orderDetail"
    (orderChangedEvent)="updateDetailOnChange()"
  />

  <div class="card-container">
    <app-detail-card
      title="Auftraggeber"
      backgroundImage="/assets/customer.svg"
      hoverBackgroundImage="/assets/customer-hover.svg"
      [zohoId]="orderDetail.customer.zohoId"
    >
      <p>
        <strong>{{ orderDetail.customer.name }}</strong> <br />
        {{ orderDetail.customer.zipCode }} {{ orderDetail.customer.city }}
        <br />
        {{ orderDetail.customer.country }}
      </p></app-detail-card
    >
    <app-detail-card
      title="Standort"
      backgroundImage="/assets/location.svg"
      hoverBackgroundImage="/assets/location-hover.svg"
      [zohoId]="orderDetail.location.zohoId"
      ><p>
        <strong>{{ orderDetail.location.name }}</strong> <br />
        {{ orderDetail.location.zipCode }} {{ orderDetail.location.city }}
        <br />
        {{ orderDetail.location.country }}
      </p>
    </app-detail-card>
    <app-detail-card
      title="Start- und Enddatum"
      backgroundImage="/assets/date.svg"
      hoverBackgroundImage="/assets/date-hover.svg"
      (click)="openDialog()"
      (keyup.enter)="openDialog()"
      (keyup.space)="openDialog()"
      tabindex="0"
    >
      <p>
        {{ orderDetail.startDate ? formatDate(orderDetail.startDate) : ' ' }}
        {{
          orderDetail.endDate ? '/ ' + formatDate(orderDetail.endDate) : ' / '
        }}
        <br />
      </p>
    </app-detail-card>

    <app-detail-card
      title="Prüfende Person"
      backgroundImage="/assets/inspector.svg"
      hoverBackgroundImage="/assets/inspector-hover.svg"
      (click)="openDialog()"
      (keyup.enter)="openDialog()"
      (keyup.space)="openDialog()"
      tabindex="0"
      ><p>
        @for (
          inspector of orderDetail.inspectors;
          track inspector.id;
          let index = $index
        ) {
          <span
            >{{ inspector.firstName }} {{ inspector.lastName }}
            @if (index !== orderDetail.inspectors.length - 1) {
              , <br />
            }
          </span>
        }
      </p>
    </app-detail-card>
  </div>
  <app-detail-table
    [orderDetail]="orderDetail"
    [aggregates]="orderDetail.aggregates"
  />
  @if (showDeleteDialog) {
    <div class="delete-dialog-container">
      <div class="delete-dialog">
        <div class="icon-container">
          <img src="/assets/trash-red.svg" alt="Delete Icon" />
        </div>

        <h3>Auftrag löschen</h3>
        <p>
          Bist du sicher, dass du den Auftrag
          <b>{{ orderDetail.orderNumber }}</b>
          löschen willst?
        </p>
        <div class="dialog-buttons">
          <app-button
            type="tertiary-button"
            label="Abbrechen"
            (click)="toggleDeleteDialog()"
          />
          <app-button
            type="primary-button-red"
            label="Löschen"
            (click)="deleteOrder(orderDetail.id)"
          />
        </div>
      </div>
    </div>
  }
}
@if (error) {
  <div class="order-headline">
    <h2>Auftrag Unbekannt</h2>
  </div>
  <div class="error-container"><app-error [error]="error" /></div>
}
