import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { FileService } from '../file.service';
import { ToastService } from '../../../../core/toast.service';

@Component({
  selector: 'app-order-excel-file-upload',
  standalone: true,
  imports: [
    ButtonComponent,
    FileUploadModule,
    ToastModule,
    CommonModule,
  ],
  templateUrl: './order-excel-file-upload.component.html',
  styleUrl: './order-excel-file-upload.component.scss',
})
export class OrderExcelFileUploadComponent {
  @Output() fileUploadSuccessEvent = new EventEmitter();

  constructor(
    private fileService: FileService,
    private toastService: ToastService
  ) {}

  onFileSelected(event: any) {
    for (const file of event.target.files) {
      if (file && file instanceof File && this.isValidExcelFile(file)) {
        this.uploadOrderExcelFile(file);
      } else {
        this.toastService.show(
          'warn',
          'Upload fehlgeschlagen!',
          'Es können nur Excel-Dateien hochgeladen werden.'
        );
      }
    }
  }

  private isValidExcelFile(file: File): boolean {
    const allowedExtensions = ['.xls', '.xlsx'];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf('.'))
      .toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  uploadOrderExcelFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.fileService.uploadOrderExcelFile(formData).subscribe({
      next: (response) => {
        this.fileUploadSuccessEvent.emit();
        this.toastService.show(
          'success',
          'Upload erfolgreich!',
          response.message
        );
      },
     // error: (error: HttpErrorResponse) => {  },
    });
  }
}
