<input
  type="file"
  class="file-input"
  multiple="true"
  (change)="onFileSelected($event)"
  #fileUpload
  accept=".xlsm"
/>
<app-button
  (click)="fileUpload.click()"
  type="primary-icon-button"
  icon="/assets/upload.svg"
  label="Produktauflistung konvertieren"
/>
